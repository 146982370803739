/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(function() {
	wWidth = $(window).width();
	wHeight = $(window).height();

    console.log('Ready!');
    initSmoothScroll();
    fn_nav_toggle();
    initNavfunctionality();
    fnCookieBar();
});

/*----------  Window Events  ----------*/

$(window).on('load resize', function() {
	wWidth = $(window).width();
	wHeight = $(window).height();
});

function initNavfunctionality() {
    if ($('header').length > 0) { // check if element exists
        $('body').css('padding-top', $('header').outerHeight());
        var last_scroll_top = 0;
        $(window).on('scroll', function () {
            scroll_top = $(this).scrollTop();
            if (scroll_top < last_scroll_top) {
                $('header').removeClass('scrolled-down').addClass('scrolled-up shadowed');
            }
            else {
                $('header').removeClass('scrolled-up shadowed').addClass('scrolled-down');
            }

            if(scroll_top == 0) {
                $('header').removeClass('scrolled-down shadowed').addClass('scrolled-up');
            }

            last_scroll_top = scroll_top;
        });
    }
}


function initSmoothScroll() {
    $('.smooth-scroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 1000);
    });

    $('.smoothScroll').on('click', function (event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).data('scrolltarget')).offset().top
        }, 1000);
    });
}
function fn_nav_toggle() {
    var target;

    var $toggler = $('[data-nav-toggle]');

    if ($toggler.length > 0 && wWidth < 576) {
        $toggler.off('click.toggle').on('click.toggle', function () {
            target = $(this).data('nav-toggle');
            var $target = $(target).first();

            if ($target.length > 0) {
                if ($target.hasClass('show')) {
                    $target.removeClass('show');
                    $('body').css('overflow', 'auto');
                    $toggler.removeClass('open');
                }
                else {
                    $target.addClass('show');
                    $('body').css('overflow', 'hidden');
                    $toggler.addClass('open');
                }
            }

            return false;
        });
    }
}

function fnCookieBar() {
    if (localStorage.getItem('cookieTerms') == 'true') {
        $('#cookieBar').hide();
    }
    $('#acceptCookieBtn').on('click', function () {
        localStorage.setItem("cookieTerms", true);
        $('#cookieBar').fadeOut();
    });
}
